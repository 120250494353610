import { Outlet, NavLink } from "react-router-dom"



const Layout =() =>{

    const cambia = () => {

        const cerrar = document.getElementById("cerrar");     
        cerrar.classList.toggle("close");
       
    }
    

return <div>
     
    <nav >
        <ul id="nav" className="nav_item" >
            <li>
                <NavLink className="cerrar" to="/Hogar"onclick={cambia}>Hogar</NavLink>
            </li>
            <li>
                <NavLink className="cerrar" to="/Empresas" onclick={cambia}>Empresas</NavLink >
            </li>
            <li>
                <NavLink className="cerrar" to="/Servicios" onclick={cambia}>Servicios</NavLink >
            </li>
            <li>
                <NavLink className="cerrar" to="/Contacto" onclick={cambia} >Contacto</NavLink >
            </li>
        </ul>

       
    </nav>
    
    <Outlet/>

    
</div>

}

export default Layout;