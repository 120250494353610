import React, { Component } from "react";
import BannerDedicado from "../Component/BannerDedicado";

import Footer from "../Component/Footer";
import Noticias from "../Component/Noticias";



class Contacto extends Component {
    render() {
        return (
            <div>
<div className="fondo">
                <div className="container contacto">

                    <form action="https://formsubmit.co/507811684c83842db6a80b52ff6908b3" method="POST">

                        <h2>Dejanos tus datos y en breve nos comunicaremos</h2>

                        <div class="mb-3">
                            
                            <label for="exampleFormControlInput1" 
                            class="form-label">Nombre</label>

                            <input 
                                type="text" 
                                class="form-control" 
                                name="name"
                                id="exampleFormControlInput1"
                                placeholder="Pedro Perez"/>
                        </div>

                        <div class="mb-3">
                            <label for="exampleFormControlInput1" 
                            class="form-label">Teléfono</label>

                            <input 
                                type="numb" 
                                class="form-control" 
                                name="phone"
                                id="exampleFormControlInput1"
                                placeholder="304 52555225"/>
                        </div>

                        <div class="mb-3">
                            <label for="exampleFormControlInput1" 
                            class="form-label">Correo Eléctronico</label>

                            <input 
                                type="email" 
                                class="form-control" 
                                id="exampleFormControlInput1"
                                name="email"
                                placeholder="name@example.com"/>
                        </div>


                        
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" 
                            class="form-label">Escribe aqui tus comentarios </label>

                            <textarea 
                            class="form-control" 
                            name="message"
                            id="exampleFormControlTextarea1" 
                            rows="3"></textarea>
                        </div>

                        
                        <button type="sumit">Enviar</button>
                  

                        <input type="hidden" name="_next" value="https://internext.com.co" />

                        <input type="hidden" name="_captcha" value="false"></input>

                    </form>

                </div>

                </div>


                <div>
                    <BannerDedicado />
                </div>
                <div>
                    <Noticias />
                </div>

                <div>
                    <Footer />
                </div>

            </div>
        );
    }
}

export default Contacto